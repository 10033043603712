<template>
  <div class="q-w360 q-fit">
    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="showMenopauseQuestion"
        class="q-mb24 q-mb32-md"
        :error="getFieldsError(['hotFlushes'])"
      >
        <single-answer-question
          title="label.hotFlushes"
          :value="hotFlushes"
          :options="$options.yesNoResponseOptions"
          yes-no
          dysfunction="skin-redness"
          @input="onFieldChange('hotFlushes', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>

    <question-with-error-wrap :error="getFieldsError(['flushesDuration'])">
      <single-answer-question
        id="flushes-duration"
        title="label.flushesDuration"
        :value="flushesDuration"
        :options="$options.flushDurationOptions"
        dysfunction="skin-redness"
        @input="onFieldChange('flushesDuration', $event)"
      />
    </question-with-error-wrap>

    <question-with-error-wrap
      class="q-mt24 q-mt32-md"
      :error="getFieldsError(['flushLocations', 'otherFlushLocations'])"
    >
      <multi-picture-select
        id="flush-locations"
        title="label.flushLocations"
        :gender="gender"
        :value="flushLocations"
        :other-value="otherFlushLocations"
        :options="$options.flushLocationsOptions"
        dysfunction="skin-redness"
        @input="onFieldChange('flushLocations', $event)"
        @other-input="onFieldChange('otherFlushLocations', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import FadeInTransitionWrap from '@/components/wrappers/FadeInTransitionWrap';
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';
import MultiPictureSelect from '@/modules/questionnaire/new-design-components/questions/MultiPictureSelect';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { YES_NO_RESPONSE_OPTIONS } from '@/modules/questionnaire/constants/questions';
import {
  FLUSH_DURATION_OPTIONS,
  FLUSH_LOCATION_OPTIONS
} from '@/modules/questionnaire/constants/steps/skinRedness.js';

export default {
  name: 'FlushingDetailTemplate',
  components: {
    FadeInTransitionWrap,
    SingleAnswerQuestion,
    QuestionWithErrorWrap,
    MultiPictureSelect
  },
  mixins: [stepTemplateMixin],
  yesNoResponseOptions: YES_NO_RESPONSE_OPTIONS,
  flushDurationOptions: FLUSH_DURATION_OPTIONS,
  flushLocationsOptions: FLUSH_LOCATION_OPTIONS,
  props: {
    gender: {
      type: String,
      required: true
    },
    hotFlushes: {
      type: String,
      default: ''
    },
    flushesDuration: {
      type: String,
      default: ''
    },
    flushLocations: {
      type: Array,
      default: () => []
    },
    otherFlushLocations: {
      type: String,
      default: ''
    },
    showMenopauseQuestion: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped></style>
