import {
  FLUSH_DURATION,
  FLUSH_LOCATION,
  OTHER_VALUE_INCLUSIVE_BINDINGS,
  CAPILLARY_FACE_LOCATION
} from '@/modules/questionnaire/api/constants';

export const FLUSH_DURATION_OPTIONS = [
  { value: FLUSH_DURATION.MANY_YEARS, text: 'flushesDuration.manyYears' },
  { value: FLUSH_DURATION.FEW_YEARS, text: 'flushesDuration.fewYears' },
  { value: FLUSH_DURATION.FEW_MONTHS, text: 'flushesDuration.fewMonths' }
];

export const FLUSH_LOCATION_OPTIONS = [
  { value: FLUSH_LOCATION.FACE, text: 'bodyZone.face' },
  { value: FLUSH_LOCATION.NECK, text: 'bodyZone.neck' },
  { value: FLUSH_LOCATION.UPPER_BODY, text: 'bodyZone.upperBody' },
  { value: FLUSH_LOCATION.ABDOMEN, text: 'bodyZone.abdomen' },
  OTHER_VALUE_INCLUSIVE_BINDINGS
];

export const CAPILLARY_FACE_LOCATION_OPTIONS = [
  { value: CAPILLARY_FACE_LOCATION.NOSE, text: 'capillaryLocation.nose' },
  { value: CAPILLARY_FACE_LOCATION.CHEEKS, text: 'capillaryLocation.cheeks' },
  { value: CAPILLARY_FACE_LOCATION.CHIN, text: 'capillaryLocation.chin' }
];
